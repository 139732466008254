import React, { useState } from 'react';
import {
  SEO,
  Clients,
  TogetherIsBetter,
  ServicesOverview,
  RecentWork,
  HomepageHeader,
  Testimonials,
  Video
} from 'components';
import styled from 'styled-components';
import { graphql } from 'gatsby';

const HomePage = ({ data }) => (
  <>
    <SEO
      pageSpecificTitle="Strategic Marketing, Virtual Events & Healthcare Communications"
      pageSpecificDescription="Agency X is a strategic marketing and virtual events agency, specializing in creative healthcare communications, brand strategy, digital marketing and virtual events."
    />
    <HomepageHeader />
    <TogetherIsBetter />
    <Clients />
    <Section>
      <Video
        style={{ marginBottom: '1.25rem' }}
        title="Agency X - If Your Thing Is..."
        url="https://player.vimeo.com/video/756308936?h=8945d580b5"
      />
    </Section>
    <ServicesOverview />
    <RecentWork caseStudies={data.caseStudies.edges} />
    <Testimonials />
  </>
);

export const Head = () => (
  <link rel="preload" as="video" type="video/mp4" href="/banner-video.mp4" />
);

const Section = styled.section`
  width: 100%;
  height: auto;
  margin: 0 auto;
  > div {
    grid-column: 2/3;
  }
`;

export const HomePageQuery = graphql`
  {
    caseStudies: allSanityCaseStudy(
      filter: { publicFacingCaseStudy: { eq: true } }
      sort: { fields: order, order: DESC }
      limit: 4
    ) {
      edges {
        node {
          title
          company
          whatWeDid
          slug {
            current
          }
          previewImage {
            alt
            asset {
              gatsbyImageData(layout: CONSTRAINED, width: 870, height: 573, formats: AUTO)
            }
          }
        }
      }
    }
  }
`;

export default HomePage;
